import { getMainLayout } from "components/main/layout/store/layoutContentSlice";
import { Button, Image, InnerHTML, Section } from "components/shared";
import { getSeo } from "components/shared/store/seoSlice";
import { throwIfError } from "lib/axios/error";
import { wrapper } from "lib/redux";
import { I404Content } from "models/error-pages";
import { GetStaticProps, NextPage } from "next";
import Link from "next/link";
import { get404ContentReq } from "rest-api/error-pages";

export const getStaticProps: GetStaticProps<I404Content> = wrapper.getStaticProps(
  ({ dispatch }) =>
    async () => {
      const { payload: mainLayoutPayload } = await dispatch(getMainLayout());
      const { payload: seoPayload } = await dispatch(getSeo("/not-found"));
      const contentPayload = await get404ContentReq();
      throwIfError([mainLayoutPayload, seoPayload, contentPayload]);

      return {
        props: contentPayload as I404Content,
      };
    },
);

const Custom404: NextPage<I404Content> = ({ image, content, backButtonText }) => {
  return (
    <Section aria-label="Page not found" className="bg-background-default">
      <style jsx global>{`
        main {
          background-color: var(--palette-background-default);
        }
      `}</style>
      <div className="container">
        <div className="mx-auto max-w-screen-xs text-center">
          <Image {...image} />
          <InnerHTML variant="p1" className="mt-8 text-center">
            {content}
          </InnerHTML>
          <Button
            className="mt-12 px-16"
            LinkComponent={Link}
            href="/"
            variant="contained"
            color="primary"
          >
            {backButtonText}
          </Button>
        </div>
      </div>
    </Section>
  );
};

export default Custom404;
